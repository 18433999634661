import {Component, OnInit} from '@angular/core';
import {ItemDataService} from '../item-data.service';
import {ItemModel} from '../item.model';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  sculptures: ItemModel[];

  constructor(private itemDataService: ItemDataService) {
  }

  ngOnInit(): void {
    this.sculptures = this.itemDataService.getItemData()
      .filter((item) => item.type === 'showcase');
  }

}
