<div class="card item-card">
  <img *ngIf="isCollapsed" class="item-image"
       (click)="toggleCollapse()"
       [src]="'/assets/img/' + item.img"
       alt="{{item.img}}"
       height="80">
  <div [ngbCollapse]="isCollapsed" id="collapseExample">
    <img class="card-img-top item-image"
         (click)="toggleCollapse()"
         [src]="'/assets/img/' + item.img"
         alt="{{item.img}}">
  </div>
  <div class="card-body" style="text-align: center">
    <div class="card-title title">
      <h6>{{item.title}}</h6>
      <h6 class="card-subtitle font-weight-light" style="width: 125px;text-align: right"><i>{{item.subtitle}}</i>
      </h6>
    </div>
  </div>
</div>

