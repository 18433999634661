<div class="card" style="width: 32rem;">
  <div class="card-body">
<!--    <ngb-carousel [showNavigationIndicators]="false">
      <ng-template ngbSlide>
        <div class="picsum-img-wrapper">
          <img class="asset-img" [src]="'/assets/img/' + sculptures[0].img" alt="Random first slide">
        </div>
        <div class="carousel-caption asset-caption">
          <h3>{{sculptures[0].title}}</h3>
        </div>
      </ng-template>
      <ng-template ngbSlide>
        <div class="picsum-img-wrapper">
          <img class="asset-img" [src]="'/assets/img/' + sculptures[1].img" alt="Random second slide">
        </div>
        <div class="carousel-caption asset-caption">
          <h3>{{sculptures[1].title}}</h3>
        </div>
      </ng-template>
      <ng-template ngbSlide>
        <div class="picsum-img-wrapper">
          <img class="asset-img" [src]="'/assets/img/' + sculptures[2].img" alt="Random third slide">
        </div>
        <div class="carousel-caption asset-caption">
          <h3>{{sculptures[2].title}}</h3>
        </div>
      </ng-template>
      <ng-template ngbSlide>
        <div class="picsum-img-wrapper">
          <img class="asset-img" [src]="'/assets/img/' + sculptures[3].img" alt="Random fourth slide">
        </div>
        <div class="carousel-caption asset-caption">
          <h3>{{sculptures[3].title}}</h3>
        </div>
      </ng-template>
      <ng-template ngbSlide>
        <div class="picsum-img-wrapper">
          <img class="asset-img" [src]="'/assets/img/' + sculptures[4].img" alt="Random fifth slide">
        </div>
        <div class="carousel-caption asset-caption">
          <h3>{{sculptures[4].title}}</h3>
        </div>
      </ng-template>
      <ng-template ngbSlide>
        <div class="picsum-img-wrapper">
          <img class="asset-img" [src]="'/assets/img/' + sculptures[5].img" alt="Random sixth slide">
        </div>
        <div class="carousel-caption asset-caption">
          <h3>{{sculptures[5].title}}</h3>
        </div>
      </ng-template>
    </ngb-carousel>-->
    <img class="asset-img" [src]="'/assets/img/' + sculptures[0].img" alt="Random sixth slide">
  </div>
</div>
