<div class="card">
  <div class="card-body">
    <table class="table">
      <ng-container *ngFor="let exhibition of exhibitions">
        <tr>
          <td rowspan="2" class="time-slot">{{exhibition.timeSlot}}</td>
          <td>{{exhibition.title}}</td>
        </tr>
        <tr>
          <td class="location">{{exhibition.location}}</td>
        </tr>
      </ng-container>
    </table>
  </div>
</div>
