import {Component, OnInit} from '@angular/core';
import {ItemModel} from '../item.model';
import {ItemDataService} from '../item-data.service';

@Component({
  selector: 'app-sculptures',
  templateUrl: './sculptures.component.html',
  styleUrls: ['./sculptures.component.scss']
})
export class SculpturesComponent implements OnInit {
  sculptures: ItemModel[];

  constructor(private itemDataService: ItemDataService) {
  }

  ngOnInit(): void {
    this.sculptures = this.itemDataService.getItemData()
      .filter((item) => item.type === 'sculpture');
  }

}
