import { Component, OnInit } from '@angular/core';
import {ExhibitionModel} from '../exhibition.model';
import {ItemDataService} from '../item-data.service';

@Component({
  selector: 'app-tilly',
  templateUrl: './tilly.component.html',
  styleUrls: ['./tilly.component.scss']
})
export class TillyComponent implements OnInit {
  cvData: ExhibitionModel[];

  constructor(private itemDataService: ItemDataService) { }

  ngOnInit(): void {
    this.cvData = this.itemDataService.getCvData();
  }

}
