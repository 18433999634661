import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ItemComponent} from './item/item.component';
import {ItemDataService} from './item-data.service';
import {ItemListComponent} from './item-list/item-list.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { SculpturesComponent } from './sculptures/sculptures.component';
import { HomeComponent } from './home/home.component';
import { PhotographsComponent } from './photographs/photographs.component';
import { ExhibitionsComponent } from './exhibitions/exhibitions.component';
import { TillyComponent } from './tilly/tilly.component';

@NgModule({
  declarations: [
    AppComponent,
    ItemComponent,
    ItemListComponent,
    SculpturesComponent,
    HomeComponent,
    PhotographsComponent,
    ExhibitionsComponent,
    TillyComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule
  ],
  providers: [ItemDataService],
  bootstrap: [AppComponent]
})
export class AppModule {
}
