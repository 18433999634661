import {Component, Input, OnInit} from '@angular/core';
import {ItemModel} from '../item.model';

@Component({
  selector: 'app-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss']
})
export class ItemComponent implements OnInit {
  @Input() item: ItemModel;
  public isCollapsed: boolean;

  constructor() {
  }

  ngOnInit(): void {
    this.isCollapsed = true;
  }

  toggleCollapse(): void {
    this.isCollapsed = !this.isCollapsed;
  }

}
