import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  routes = [
    {linkName: 'home', url: 'home'},
    {linkName: 'beelden', url: 'sculptures'},
    {linkName: 'fotografie', url: 'photographs'},
    {linkName: 'exposities', url: 'exhibitions'},
    {linkName: 'tilly', url: 'tilly'}
  ];

  constructor() {
  }

  ngOnInit(): void {
  }
}
