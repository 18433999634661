import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {SculpturesComponent} from './sculptures/sculptures.component';
import {HomeComponent} from './home/home.component';
import {PhotographsComponent} from './photographs/photographs.component';
import {ExhibitionsComponent} from './exhibitions/exhibitions.component';
import {TillyComponent} from './tilly/tilly.component';


const routes: Routes = [
  {path: '', redirectTo: '/home', pathMatch: 'full'},
  {path: 'home', component: HomeComponent},
  {path: 'sculptures', component: SculpturesComponent},
  {path: 'photographs', component: PhotographsComponent},
  {path: 'exhibitions', component: ExhibitionsComponent},
  {path: 'tilly', component: TillyComponent},
  {path: '**', redirectTo: '/home', pathMatch: 'full'}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
