import {Component, OnInit} from '@angular/core';
import {ExhibitionModel} from '../exhibition.model';
import {ItemDataService} from '../item-data.service';

@Component({
  selector: 'app-exhibitions',
  templateUrl: './exhibitions.component.html',
  styleUrls: ['./exhibitions.component.scss']
})
export class ExhibitionsComponent implements OnInit {
  exhibitions: ExhibitionModel[];

  constructor(private itemDataService: ItemDataService) {
  }

  ngOnInit(): void {
    this.exhibitions = this.itemDataService.getExhibitionData();
  }

}
