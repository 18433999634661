import {Component, OnInit} from '@angular/core';
import {ItemModel} from '../item.model';
import {ItemDataService} from '../item-data.service';

@Component({
  selector: 'app-photographs',
  templateUrl: './photographs.component.html',
  styleUrls: ['./photographs.component.scss']
})
export class PhotographsComponent implements OnInit {
  photographs: ItemModel[];

  constructor(private itemDataService: ItemDataService) {
  }

  ngOnInit(): void {
    this.photographs = this.itemDataService.getItemData()
      .filter((item) => item.type === 'photograph')
      .reverse();
  }

}
