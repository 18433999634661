import {Injectable} from '@angular/core';
import {ItemModel} from './item.model';
import itemData from './itemdata.json';
import exhibitionData from './exhibitiondata.json';
import {ExhibitionModel} from './exhibition.model';
import cvData from './cvdata.json';

@Injectable({
  providedIn: 'root'
})
export class ItemDataService {
  itemData: ItemModel[];
  exhibitionData: ExhibitionModel[];
  cvData: ExhibitionModel[];

  constructor() {
    this.itemData = itemData.reverse();
    this.exhibitionData = exhibitionData.reverse();
    this.cvData = cvData.reverse();
  }

  getItemData(): ItemModel[] {
    return this.itemData;
  }

  getExhibitionData(): ExhibitionModel[] {
    return this.exhibitionData ;
  }

  getCvData(): ExhibitionModel[] {
    return this.cvData ;
  }
}
