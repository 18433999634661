<div class="card">
  <h3 class="card-title title">artist statement</h3>
  <div class="card-body">
    <div class="card-text text">
      <img [src]="'/assets/img/tilly.png'" style="margin-bottom: 4px; width:200px;">
      <p>het zijn vooral de schoonheid en kracht van de meest elementaire en primitieve levensvormen en hun (mogelijke)
        ontwikkeling die mij fascineren en aanzetten tot het maken van driedimensionale abstracte organische vormen.
        mijn
        beelden ontstaan door voortdurend experimenteren, spelen en zoeken naar en met vormen en materialen in diverse
        mogelijke en schijnbaar onmogelijke combinaties.</p>

      <p>de (detail-)foto’s die ik maak van verval/vergankelijkheid van zowel natuur als cultuur, zijn voor mij in de
        eerste
        plaats een bron van inspiratie. daarnaast raken mijn foto’s en beelden in de loop der jaren steeds vaker met
        elkaar
        verweven en vormen samen één krachtiger beeld.</p>

      <p>mijn doel is om beelden te creëren die door hun vorm en uiterlijk een natuurlijke basale kracht en eenvoud
        uitstralen. beelden die passen in hun omgeving, die emotie of een glimlach ontlokken en wellicht aanzetten tot
        nadenken.</p>
    </div>
    <div class="d-flex align-items-end">
      <div><img [src]="'/assets/img/hustlings.png'" style="margin-bottom: 4px; margin-right: 4px; width:200px;"></div>
      <div>
        hustlings <br>
        met op achtergrond <br>
        foto "evanscent".
      </div>
    </div>
  </div>
</div>
<div class="card"><h3 class="card-title title">opleiding/ervaring</h3>
  <div class="card-body">
    <table class="table">
      <ng-container *ngFor="let cv of cvData">
        <tr>
          <td rowspan="2" class="time-slot">{{cv.timeSlot}}</td>
          <td>{{cv.title}}</td>
        </tr>
        <tr>
          <td class="location">{{cv.location}}</td>
        </tr>
      </ng-container>
    </table>
  </div>
</div>
